import React from "react";
import Texts from "../../../utils/lang-utils";
import shalimarDemo from "../../../areas/main/assets/videos/shalimar-demo.mp4";
import background from '../../../areas/main/assets/images/background/banner-2-bg.jpg';
import urls from "../../../areas/main/assets/urls";

const OtherServices = () => {
  return (
    <section className="service-one d-flex align-items-center" style={{ backgroundImage: `url('${background}')` }}>
              <div className="overlay"></div>
      <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
        <source src={shalimarDemo} type="video/mp4" />
      </video>
      <div className="container text-center ">
        <div className="block-title text-center">
          <h2 className="block-title__title light" dangerouslySetInnerHTML={{ __html: Texts.QRMenu.OtherServicesTitle}} />
        </div>
        <a href={urls.qrOrdering} className="thm-btn">
                <span>{Texts.QRMenu.QROrdering}</span>
              </a>
        <a href={urls.apps} className="thm-btn">
        <span>{Texts.QRMenu.WaiterKitchenApps}</span>
        </a>
    </div>
    </section>
  );
};
export default OtherServices;
